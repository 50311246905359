import VueRouter from "vue-router";

export const router = new VueRouter({
  mode: "history",
  linkExactActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Home/DefaultHomeView"
        ),
      name: "home",

      meta: {
        componentRootPath: "/Home",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/rental-venue",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Organizers/RentalVenue/RentalVenueMain"
        ),
      name: "rentalVenue",
      meta: {
        keepAlive: true,
        componentRootPath: "/Organizers/RentalVenue",
      },
    },
    {
      path:
        "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/rental-venue/space/:space_id",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Organizers/RentalVenueSpace/RentalVenueSpaceMain"
        ),
      name: "rentalVenueSpace",
      meta: {
        componentRootPath: "/Organizers/RentalVenueSpace",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/organizers-facility",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Organizers/Facility/FacilityMain"
        ),
      name: "organizersFacility",
      meta: {
        componentRootPath: "/Organizers/Facility",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/organizers-exhibition",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Organizers/Exhibition/ExhibitionMain"
        ),
      name: "organizersExhibition",
      meta: {
        componentRootPath: "/Organizers/Exhibition",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/organizers-album",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Organizers/Album/AlbumMain"
        ),
      name: "organizersAlbum",
      meta: {
        keepAlive: true,
        componentRootPath: "/Organizers/Album",
      },
    },
    {
      path:
        "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/organizers-album/album/:albumId",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Organizers/AlbumPhoto/AlbumPhotoBodyMain"
        ),
      name: "organizers-album",
      meta: {
        componentRootPath: "/Organizers/AlbumPhoto",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/organizers-document",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Organizers/DocumentMain"
        ),
      name: "organizersDocument",
      meta: {
        componentRootPath: "/Press/Document",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/visitors-facility",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Visitors/Facility/FacilityMain"
        ),
      name: "visitorsFacility",

      meta: {
        componentRootPath: "/Organizers/Facility",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/visitors-exhibition",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Visitors/ExhibitionMain"
        ),
      name: "visitorsExhibition",

      meta: {
        keepAlive: true,
        componentRootPath: "/Organizers/Exhibition",
      },
    },
    {
      path:
        "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/visitors-exhibition/event/:activity_event_id",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Visitors/ExhibitionPage/ExhibitionPageMain"
        ),
      name: "visitorsExhibitionEvent",

      meta: {
        componentRootPath: "/Visitors/ExhibitionPage",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/traffic",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Visitors/Traffic/TrafficMain"
        ),
      name: "traffic",

      meta: {
        componentRootPath: "/Visitors/Traffic",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/accommodation",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Visitors/Accommodation/AccommodationMain"
        ),
      name: "accommodation",

      meta: {
        componentRootPath: "/Visitors/Accommodation",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/news",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Press/News/NewsMain"
        ),
      name: "news",

      meta: {
        componentRootPath: "/Press/News",
      },
    },
    // {
    //     path: '/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/news/:id',
    //     component: () => import('./components/DefaultHome/'+process.env.VUE_APP_TEMPLATE+'/Press/News/NewsMain')
    // },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/press-album",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Press/PressAlbumMain"
        ),
      name: "pressAlbum",

      meta: {
        keepAlive: true,
        componentRootPath: "/Organizers/Album",
      },
    },
    {
      path:
        "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/press-album/album/:albumId",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Press/AlbumPhotoBodyMain"
        ),
      name: "press-album",
      meta: {
        componentRootPath: "/Organizers/AlbumPhoto",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/video",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Press/Video/VideoMain"
        ),
      name: "video",

      meta: {
        componentRootPath: "/Press/Video",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/press-document",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Press/Document/DocumentMain"
        ),
      name: "pressDocument",

      meta: {
        componentRootPath: "/Press/Document",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/join-us",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/AboutUs/JoinUs/JoinUsMain"
        ),
      name: "joinUs",

      meta: {
        componentRootPath: "/AboutUs/JoinUs",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/team",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/AboutUs/Team/TeamMain"
        ),
      name: "team",

      meta: {
        componentRootPath: "/AboutUs/Team",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/contact-us",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/AboutUs/ContactUs/ContactUsMain"
        ),
      name: "contactUs",

      meta: {
        componentRootPath: "/AboutUs/ContactUs",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/kec",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/AboutUs/AboutKEC/AboutKECMain"
        ),
      name: "kec",

      meta: {
        componentRootPath: "/AboutUs/AboutKEC",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/esg",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/AboutUs/AboutESG/AboutESGMain"
        ),
      name: "esg",

      meta: {
        componentRootPath: "/AboutUs/AboutESG",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/privacy",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Declare/Privacy/PrivacyMain"
        ),
      name: "privacy",

      meta: {
        componentRootPath: "/Declare/Privacy",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/terms",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Declare/Terms/TermsMain"
        ),
      name: "terms",

      meta: {
        componentRootPath: "/Declare/Terms",
      },
    },
    {
      path: "/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/report",
      component: () =>
        import(
          "./components/DefaultHome/" +
            process.env.VUE_APP_TEMPLATE +
            "/Report/ReportMain"
        ),
      name: "report",

      meta: {
        componentRootPath: "/AboutUs/AboutKEC",
      },
    },
  ],
});
